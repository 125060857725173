import Icon from "@ant-design/icons";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFields } from "../../../api";
import { Loader } from "../../../components";
import { CheckMark, SingleDash } from "../../../utils/icons/svgIcons";
import { PreviewDataTypeFooter } from "./PreviewDataTypeFooter";
import { PreviewDataTypeHeader } from "./PreviewDataTypeHeader";
import "./PreviewDataType.css";

const officialFormatColumns = [
  {
    title: "Field Name",
    dataIndex: "name",
    key: "name",
    width: "22%",
    render: (name: string) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">{name}</div>
      );
    },
  },
  {
    title: "Field Input Type",
    dataIndex: "inputType",
    key: "inputType",
    width: "15.5%",
    render: (inputType: string) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {inputType}
        </div>
      );
    },
  },
  {
    title: "Column",
    dataIndex: "columnInFile",
    key: "columnInFile",
    width: "7%",
    render: (column: string) => {
      return (
        <div className="text-small-custom-2 border-r-2 text-fields-font-color">
          {column}
        </div>
      );
    },
  },
  {
    title: "Required",
    dataIndex: "required",
    key: "required",
    width: "12%",
    render: (required: boolean) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {required == true ? (
            <Icon tabIndex={1} component={CheckMark} />
          ) : (
            <Icon tabIndex={1} component={SingleDash} />
          )}
        </div>
      );
    },
  },
  {
    title: "Field Validation Type",
    dataIndex: "validationType",
    key: "validationType",
    width: "19%",
    render: (validationType: string) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {validationType ? (
            validationType === "AcceptableValues" ? (
              "Acceptable Values"
            ) : (
              validationType
            )
          ) : (
            <Icon tabIndex={1} component={SingleDash} />
          )}
        </div>
      );
    },
  },
  {
    title: "Acceptable Values",
    dataIndex: "acceptableValues",
    key: "acceptableValues",
    width: "22%",
    render: (acceptableValues: any, record: any) => {
      const { validationType, range, maxCharacter, dateFormat } = record;
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {validationType ? (
            validationType === "Range" ? (
              `${range.min} - ${range.max}`
            ) : validationType === "AcceptableValues" ? (
              Object.keys(acceptableValues)
                .map((val) => acceptableValues[val])
                .join(", ")
            ) : validationType === "MaxCharacters" ? (
              maxCharacter
            ) : (
              dateFormat
            )
          ) : (
            <Icon tabIndex={1} component={SingleDash} />
          )}
        </div>
      );
    },
  },
];

const customFormatColumns = [
  {
    title: "Field Name",
    dataIndex: "name",
    key: "name",
    width: "23%",
    render: (name: string) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">{name}</div>
      );
    },
  },
  {
    title: "Field Type",
    dataIndex: "inputType",
    key: "inputType",
    width: "13%",
    render: (inputType: string) => {
      return (
        <div className="border-r-2 text-small-custom-2 text-fields-font-color">
          {inputType}
        </div>
      );
    },
  },
  {
    title: "Required",
    dataIndex: "required",
    key: "required",
    width: "12%",
    render: (required: boolean) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {required == true ? (
            <Icon tabIndex={1} component={CheckMark} />
          ) : (
            <Icon tabIndex={1} component={SingleDash} />
          )}
        </div>
      );
    },
  },
  {
    title: "Field Validation Type",
    dataIndex: "validationType",
    key: "validationType",
    width: "20%",
    render: (validationType: string) => {
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {validationType ? (
            validationType === "AcceptableValues" ? (
              "Acceptable Values"
            ) : (
              validationType
            )
          ) : (
            <Icon tabIndex={1} component={SingleDash} />
          )}
        </div>
      );
    },
  },
  {
    title: "Acceptable Values",
    dataIndex: "acceptableValues",
    key: "acceptableValues",
    width: "22%",
    render: (acceptableValues: any, record: any) => {
      const { validationType, range, maxCharacter, dateFormat } = record;
      return (
        <div className="text-small-custom-2 text-fields-font-color">
          {validationType ? (
            validationType === "Range" ? (
              `${range.min} - ${range.max}`
            ) : validationType === "AcceptableValues" ? (
              Object.keys(acceptableValues)
                .map((val) => acceptableValues[val])
                .join(", ")
            ) : validationType === "MaxCharacters" ? (
              maxCharacter
            ) : (
              dateFormat
            )
          ) : (
            <Icon tabIndex={1} component={SingleDash} />
          )}
        </div>
      );
    },
  },
];

export const PreviewDataType = () => {
  const location = useLocation();
  const dataTypes: any = location.state;
  const columns =
    dataTypes.format === "Custom" ? customFormatColumns : officialFormatColumns;

  const [fieldsData, setFieldsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFieldsData = (dataTypeId: string) => {
    getFields(dataTypeId)
      .then((res) => {
        setFieldsData(res.data);
      })
      .catch((error) => {
        console.log("Error fetching fields: ", error);
      })
      .finally(() => setLoading(false));
  };

  const setFieldsFromDataType = () => {
    setFieldsData(dataTypes.fields);
    setLoading(false);
  };

  useEffect(() => {
    dataTypes.fields?.length === (0 || undefined)
      ? getFieldsData(dataTypes.id)
      : setFieldsFromDataType();
  }, []);

  return (
    <div className="font-open-sans px-10">
      <PreviewDataTypeHeader dataType={dataTypes} />
      <div className="table-header border-b-[1px] mb-32">
        {loading && <Loader size="medium" />}
        {!loading && (
          <>
            <p className="font-open-sans text-xl text-flat-gray-color mt-10 mb-4">
              Fields
            </p>
            <Table
              data-test-id="fields-list-table"
              rowKey={(record) => record.id}
              dataSource={fieldsData}
              columns={columns}
              pagination={false}
            />
          </>
        )}
      </div>
      <div>
        <PreviewDataTypeFooter dataType={dataTypes} />
      </div>
    </div>
  );
};
