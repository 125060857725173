import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateDataType, getFields } from "../../../api";
import Button from "../../../components/Button";
import { successAlert, errorAlert } from "../../../utils/toast/Toast";

export const PreviewDataTypeFooter = ({ dataType }: any) => {
  const { id, groupingId, status, name } = dataType;
  const navigate = useNavigate();
  const [double, setDouble] = useState(false);

  const updateDataTypeStatus = async (newStatus: string) => {
    const updatedDataTypeInput = {
      status: newStatus,
    };
    try {
      setDouble(true);
      const fields = await getFields(id);
      if (fields.data.length !== 0) {
        const res = await updateDataType(updatedDataTypeInput, groupingId, id);
        if (res.status === 200) {
          navigate("/");
          if (res.data.status === "Test") {
            successAlert(`${name} in Test status`);
          } else if (res.data.status === "Published") {
            successAlert(
              <div>
                {name} Published.
                <br />
                Data type is now available for imports
              </div>
            );
          } else {
            successAlert(`${name} Unpublished.`);
          }
        }
      } else {
        navigate("/");
        errorAlert(
          `Data Type is missing fields to be imported. Unable to ${updatedDataTypeInput.status} import the data type.`
        );
      }
    } catch (err) {
      navigate("/");
      errorAlert(`Error while updating the status of ${name}`);
      console.log("Error while updating the dataType", err);
    }
  };

  const actionsOnDataType = (dataTypeStatus: string) => {
    if (dataTypeStatus === "Published" || dataTypeStatus === "UnPublished") {
      return (
        <Button
          disabled={double}
          onClick={() =>
            dataTypeStatus === "Published"
              ? updateDataTypeStatus("UnPublished")
              : updateDataTypeStatus("Published")
          }
          className="bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-white font-open-sans font-normal py-2 px-4 rounded hover:from-dark-blue-color hover:to-dark-blue-color text-center preview-button"
        >
          {dataTypeStatus === "Published" ? "Unpublish" : "Publish"}
        </Button>
      );
    } else {
      return (
        <>
          <Button
            onClick={() => {
              navigate(`/datatype/edit/${id}`);
            }}
            className="border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded px-6 py-2 text-center dark:hover:border-hover-color dark:bg-white mr-[17px] save-and-close-button"
          >
            Edit
          </Button>
          <Button
            disabled={double}
            onClick={() =>
              dataTypeStatus === "Draft"
                ? updateDataTypeStatus("Test")
                : updateDataTypeStatus("Published")
            }
            className="bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-white font-open-sans font-normal py-2 px-4 rounded hover:from-dark-blue-color hover:to-dark-blue-color text-center preview-button"
          >
            {dataTypeStatus === "Draft" ? "Test Import Type" : "Publish"}
          </Button>
        </>
      );
    }
  };

  return (
    <footer className="flex flex-row justify-between items-center bg-white text-3xl text-white text-center border-t-2 fixed inset-x-0 bottom-0 h-16 datatype-details-footer">
      <div>
        <Button
          onClick={() => navigate("/")}
          type="button"
          className="font-open-sans font-bold text-cancel-button-color text-small-custom-3 text-center datatype-details-close-button"
        >
          Cancel
        </Button>
      </div>
      <div>{actionsOnDataType(status)}</div>
    </footer>
  );
};
