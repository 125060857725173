import Icon from "@ant-design/icons";
import { Table, Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteDataTypes,
  getDataTypes,
  updateDataType,
  getFields,
} from "../../../api";
import { Loader } from "../../../components";
import { dataTypesType } from "../../../types";
import { dateWithTime } from "../../../utils";
import { ActionIcon } from "../../../utils/icons/svgIcons";
import { errorAlert, successAlert } from "../../../utils/toast/Toast";
import "./ListDataTypes.css";

type listDataTypesProps = {
  groupingId: string;
};

const actionMenu = (
  status: string,
  record: dataTypesType,
  deleteDataType: Function,
  updateDataTypeStatus: Function
) => {
  if (status === "Draft") {
    return (
      <Menu>
        <Menu.Item key={1}>
          <Link to={`/datatype/edit/${record.id}`}>Edit</Link>
        </Menu.Item>
        <Menu.Item key={2}>
          <Link to={`/datatype/preview/${record.id}`} state={record}>
            Preview
          </Link>
        </Menu.Item>
        <Menu.Item
          key={3}
          onClick={() => {
            updateDataTypeStatus(record, "Test");
          }}
        >
          Test
        </Menu.Item>
        <Menu.Item
          key={4}
          onClick={() => {
            deleteDataType(record);
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  } else {
    return (
      <Menu>
        <Menu.Item key={1}>
          <Link to={`/datatype/preview/${record.id}`} state={record}>
            Preview
          </Link>
        </Menu.Item>
        {status === "Published" ? (
          <Menu.Item
            key={2}
            onClick={() => {
              updateDataTypeStatus(record, "UnPublished");
            }}
          >
            Unpublish
          </Menu.Item>
        ) : (
          <Menu.Item
            key={3}
            onClick={() => {
              updateDataTypeStatus(record, "Published");
            }}
          >
            Publish
          </Menu.Item>
        )}
      </Menu>
    );
  }
};

export const ListDataTypes = ({ groupingId }: listDataTypesProps) => {
  const [dataTypes, setdataTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataTypesData = () => {
    getDataTypes(groupingId)
      .then((res) => {
        setdataTypes(res.data);
      })
      .catch((error) => {
        console.log("Error fetching datatypes: ", error);
      })
      .finally(() => setLoading(false));
  };

  const updateDataTypeStatus = async (
    record: dataTypesType,
    newStatus: string
  ) => {
    const { id, name } = record;
    const updatedDataTypeInput = {
      status: newStatus,
    };
    try {
      setLoading(true);
      const fields = await getFields(id);
      if (fields.data.length !== 0) {
        const res = await updateDataType(
          updatedDataTypeInput,
          record.groupingId,
          id
        );
        if (res.status === 200) {
          if (res.data.status === "Test") {
            successAlert(`${name} in Test status`);
          } else if (res.data.status === "Published") {
            successAlert(
              <div>
                {name} Published.
                <br />
                Data type is now available for imports
              </div>
            );
          } else {
            successAlert(`${name} Unpublished.`);
          }
          getDataTypesData();
        }
      } else {
        errorAlert(
          `Data Type is missing fields to be imported. Unable to ${updatedDataTypeInput.status} import the data type.`
        );
      }
    } catch (err) {
      errorAlert(`Error while updating the status of ${name}`);
      console.log("Error while updating the dataType", err);
    } finally {
      setLoading(false);
    }
  };

  const deletedSuccessAlert = () =>
    successAlert("Data Type Deleted Successfully");
  const deletingFailedAlert = () => errorAlert("Error in Deleteing Data Type");
  const deleteDataType = async (record: dataTypesType) => {
    if (
      confirm("This Action Permanently Deletes the Data Type from Grouping") ==
      true
    ) {
      try {
        setLoading(true);
        await deleteDataTypes(record.groupingId, record.id);
        deletedSuccessAlert();
        getDataTypesData();
      } catch (err) {
        console.log("Error in deleting data type", err);
        deletingFailedAlert();
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      title: "Data Type",
      dataIndex: "name",
      key: "name",
      width: "25.5%",
      render: (dataType: string) => {
        return <div className="text-small-custom-2">{dataType}</div>;
      },
    },
    {
      title: "Date Created",
      dataIndex: "CT",
      key: "CT",
      width: "25%",
      render: (date: string, record: any) => {
        return (
          <div className="text-small-custom-2">
            {date ? `${dateWithTime(date)} (${record.CB})` : "--"}
          </div>
        );
      },
    },
    {
      title: "Date Published",
      dataIndex: "PT",
      key: "PT",
      width: "25%",
      render: (date: string, record: any) => {
        return (
          <div className="text-small-custom-2">
            {date ? `${dateWithTime(date)} (${record.PB})` : "--"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "11%",
      render: (status: string) => {
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            id={
              status === "Published"
                ? "grouping-badge-published"
                : status === "UnPublished"
                ? "grouping-badge-unpublished"
                : status === "Draft"
                ? "grouping-badge-draft"
                : "grouping-badge-test"
            }
          >
            {status === "UnPublished"
              ? "Unpublished"
              : status === "Published"
              ? "Published"
              : status === "Draft"
              ? "Draft"
              : "Test"}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: "8%",
      dataIndex: "status",
      render: (status: string, record: dataTypesType) => (
        <div
          className="cursor-pointer ml-3"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            trigger={["click"]}
            overlay={actionMenu(
              status,
              record,
              deleteDataType,
              updateDataTypeStatus
            )}
            placement="bottom"
            arrow
            overlayClassName="custom-action-dropdown"
          >
            <Icon tabIndex={1} component={ActionIcon} />
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getDataTypesData();
  }, []);

  return (
    <>
      {loading && <Loader size="medium" />}
      {!loading && (
        <Table
          data-test-id="dataType-list-table"
          // @ts-ignore
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-dark" : "table-row-light"
          }
          rowKey={(record) => record.id}
          dataSource={dataTypes}
          columns={columns}
          pagination={false}
        />
      )}
    </>
  );
};
