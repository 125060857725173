import { Table } from "antd";
import { useState, useEffect } from "react";
import TextField from "../../../components/Forms/TextField";
import { dataTypeFields } from "../../../types";
import { defaultUniqueFieldsValues } from "./constants";

type inputFieldsProps = {
  appendUniqueFields: (fieldsData: any) => void;
};

const multiRowRender = (text: string[]) =>
  text.map((t, index: number) => (
    <div className="my-8" key={t + index}>
      {t}
    </div>
  ));
let initValues = {} as any;
interface DataType {
  key: React.Key;
  names: string[];
  type: string[];
  fields: string[];
  column: number;
  selected?: boolean;
}

const officialIdentifierGroups: DataType[] = [
  {
    key: "AB",
    names: ["First Name", "Last Name", "Birthdate"],
    type: ["Text", "Text", "Date"],
    fields: ["firstName", "lastName", "birthDate"],
    column: 1,
  },
  {
    key: "CD",
    names: ["Student ID", "Last Name"],
    type: ["Number", "Text"],
    fields: ["studentId", "lastName"],
    column: 2,
  },
  {
    key: "EF",
    names: ["State Student ID", "Last Name"],
    type: ["Number", "Text"],
    fields: ["stateStudentId", "lastName"],
    column: 3,
    selected: true,
  },
];

let selectedDataTypeKey;
const inputValues = [[], [], []];

export const InputOfficialFieldsComponent = ({
  appendUniqueFields,
}: inputFieldsProps) => {
  const [uniqueFieldsValues, setuniqueFieldsValues] = useState<
    Array<dataTypeFields>
  >(defaultUniqueFieldsValues);

  const addUniqueField = (fieldsData: any) => {
    const updatedUniqueFieldsValues: dataTypeFields[] = [];
    const { names, type } = fieldsData;
    names.forEach((val: string, index: number) => {
      updatedUniqueFieldsValues.push({
        name: val,
        inputType: type[index],
        required: true,
        columnInFile: "",
      });
    });
    setuniqueFieldsValues(updatedUniqueFieldsValues);
  };

  const rowSelection = {
    onChange: (selectedRowKey: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKey: ${selectedRowKey}`,
        "selectedRows: ",
        selectedRows
      );
      addUniqueField(selectedRows[0]);
    },
  };

  const columns = [
    {
      title: "Field Name",
      dataIndex: "names",
      render: multiRowRender,
    },
    {
      title: "Field Input Type",
      dataIndex: "type",
      render: multiRowRender,
    },
    {
      title: "Column in file",
      dataIndex: "names",
      render: (fields: string[], _record: any, ind: number) => {
        const formField = {
          type: "text",
          label: "",
          placeholder: "",
          maxLength: 2,
          value: "",
        };
        return fields.map((name: string, index: number) => {
          return (
            <TextField
              keyId={name + ind + index}
              key={name + ind + index}
              name={name}
              {...formField}
              values={initValues}
              handleChange={(e) => {
                inputValues[ind][index] = e.target.value.toUpperCase() as never;
                const newUnique = uniqueFieldsValues.map((obj) =>
                  obj.name === name
                    ? { ...obj, columnInFile: e.target.value.toUpperCase() }
                    : obj
                );
                setuniqueFieldsValues(newUnique);
              }}
              errorFunc={(input: string) => {
                input = input.toUpperCase();
                const characters = Array.from(input);
                if (characters.length > 2) {
                  return true;
                }
                const regex = new RegExp("[A-Z]");
                for (let i = 0; i < characters.length; i++) {
                  if (!regex.test(characters[i])) {
                    return true;
                  }
                }

                const columnValues = [...inputValues[ind]];
                columnValues.splice(index, 1);

                const isUsed =
                  columnValues.findIndex((value: string) => input === value) >=
                  0;
                return isUsed;
              }}
              inputClasses="uppercase"
            />
          );
        });
      },
    },
  ];

  useEffect(() => {
    appendUniqueFields(uniqueFieldsValues);
  }, [uniqueFieldsValues]);

  return (
    <div className="input-fields-official">
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
          defaultSelectedRowKeys: ["firstName"],
        }}
        columns={columns}
        dataSource={officialIdentifierGroups}
        pagination={false}
        rowKey={(obj) => obj.fields[0]}
        onRow={(record: any) => {
          for (let i = 0; i < initValues.length; i++) {
            if (record.column - 1 !== i) {
              initValues[i] = [];
            }
          }
          initValues = {} as any;
          selectedDataTypeKey = record.column;
          return selectedDataTypeKey;
        }}
      />
    </div>
  );
};
