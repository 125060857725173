import "./CreateDataType.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createNewDataType } from "../../../api";
import { Loader } from "../../../components";
import Checkbox from "../../../components/Forms/Checkbox";
import Dropdown from "../../../components/Forms/Dropdown";
import formValues from "../../../components/Forms/formValues";
import Radio from "../../../components/Forms/Radio";
import TextField from "../../../components/Forms/TextField";
import useForm from "../../../components/Forms/useForm";
import { createDataType } from "../../../types/dataTypesType";
import { successAlert } from "../../../utils/toast/Toast";
import { initialValues, formData } from "./constants";
import { DataTypeDetailsFooter } from "./DataTypeDetailsFooter";
import { DataTypeDetailsHeader } from "./DataTypeDetailsHeader";
import { DataTypeFieldsComponent } from "./DataTypeFields";

export const CreateDataType = () => {
  const [initValues, setInitValues] = useState<createDataType>(initialValues);
  const [fieldsValues, setFieldsValues] = useState([]);
  const [formError, setFormError] = useState({
    fieldErr: false,
    columnErr: false,
  });
  const [loading, setLoading] = useState(false);
  let saveAndClose = false;
  const navigate = useNavigate();
  const { groupingId } = useParams();

  const dataTypeCreationFailedAlert = () =>
    toast.error(
      "Error while saving data type. Duplicate Field Name or Column in File found!.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      }
    );

  const setSaveAndClose = () => {
    saveAndClose = true;
  };

  const saveDataType = async (dataType: createDataType, groupingsId: any) => {
    try {
      setLoading(true);
      const res = await createNewDataType(dataType, groupingsId);
      setLoading(false);
      if (res.status === 201) {
        if (saveAndClose === true) {
          navigate("/");
          successAlert("Successfully! created new data type.");
        } else {
          if (res.data.format === "Official") {
            navigate(`/datatype/preview/${res.data.id}`, {
              state: res.data,
            });
          } else {
            navigate(`/datatype/preview/${res.data.id}`, {
              state: res.data,
            });
          }
        }
        window.location.reload();
      }
    } catch (err) {
      console.log("Error while creating new Data type", err);
      setLoading(false);
      dataTypeCreationFailedAlert();
    }
  };

  let { values, handleSubmit } = useForm({
    initialValues: initValues,
    onSubmit: async () => {
      if (initValues.format === "Official") {
        const combinedFieldsValues = [...fieldsValues, ...initValues.fields];
        const finalData: createDataType = {
          ...initValues,
          ...{ fields: combinedFieldsValues },
        };
        await saveDataType(finalData, groupingId);
      } else {
        await saveDataType(initValues, groupingId);
      }
    },
  });

  const handleChange = (event: any) => {
    const { target } = event;
    const { name, value, type } = target;
    const formValuesObj: { [key: string]: any } = initValues;
    if (type === "checkbox") {
      formValuesObj[name] = event.currentTarget.checked ? true : false;
    } else {
      formValuesObj[name] = value;
    }

    if (type === "radio" && name === "format") {
      const newFields = [
        {
          name: "",
          inputType: "Text",
          required: false,
          columnInFile: "",
          description: "",
        },
      ];
      formValuesObj.fields = newFields;
    }
    let newValues = { values: { ...initialValues, ...formValuesObj } };
    const valuesObj = formValues(newValues);
    setInitValues(valuesObj);
    return valuesObj;
  };

  const saveFormData = () => {
    const submitBtn = document.getElementById("submitForm");
    if (formError.columnErr || formError.fieldErr) {
      dataTypeCreationFailedAlert();
    } else {
      submitBtn?.click();
    }
  };

  const changeInitValues = (updateInitValues: createDataType) => {
    setInitValues(updateInitValues);
  };
  const appendUniqueFields = (fieldsData: any) => {
    setFieldsValues(fieldsData);
  };
  const updateFormError = (errStatus: any) => {
    setFormError({ ...formError, ...errStatus });
  };

  const renderFormFields = (formField: any, keyId: number) => {
    return (
      <div key={keyId} className={`${formField.name} flex-1`}>
        {formField.type === "text" && (
          <TextField
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "dropdown" && (
          <Dropdown
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "radio" && (
          <Radio
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "checkbox" && (
          <Checkbox
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {loading && <Loader size="medium" />}
      {!loading && (
        <div className="pl-10">
          <ToastContainer />
          <DataTypeDetailsHeader importStatus="Draft" importType="Create" />
          <div className="font-open-sans font-normal not-italic">
            <form
              className="bg-white rounded pt-[25px] pb-8 mb-4 data-type-form"
              onSubmit={handleSubmit}
              key={values.id ? values.id : ""}
              id={values.id ? values.id : ""}
            >
              {formData.map((formField: any, keyId: number) => {
                return renderFormFields(formField, keyId);
              })}
              <DataTypeFieldsComponent
                data={initValues}
                changeInitValues={changeInitValues}
                appendUniqueFields={appendUniqueFields}
                updateFormError={updateFormError}
              />
              <button id="submitForm" type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form>
          </div>
          <DataTypeDetailsFooter
            saveFormData={saveFormData}
            setSaveAndClose={setSaveAndClose}
          />
        </div>
      )}
    </>
  );
};
