import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getDataType, updateDataType } from "../../../api";
import { Loader } from "../../../components";
import Checkbox from "../../../components/Forms/Checkbox";
import Dropdown from "../../../components/Forms/Dropdown";
import formValues from "../../../components/Forms/formValues";
import Radio from "../../../components/Forms/Radio";
import TextField from "../../../components/Forms/TextField";
import useForm from "../../../components/Forms/useForm";
import { editDataType } from "../../../types/dataTypesType";
import { successAlert } from "../../../utils/toast/Toast";
import { initialValues, formData } from "../create/constants";
import { DataTypeDetailsFooter } from "../create/DataTypeDetailsFooter";
import { DataTypeDetailsHeader } from "../create/DataTypeDetailsHeader";
import { DataTypeFieldsComponent } from "../create/DataTypeFields";

export const EditDataType = () => {
  const [initValues, setInitValues] = useState<editDataType | null>(null);
  const [fieldsValues, setFieldsValues] = useState([]);
  const [formError, setFormError] = useState({
    fieldErr: false,
    columnErr: false,
  });
  const [loading, setLoading] = useState(true);
  let saveAndClose = false;
  const navigate = useNavigate();
  const { dataTypeId } = useParams();

  const dataTypeEditFailedAlert = (msg: any) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

  const setSaveAndClose = () => {
    saveAndClose = true;
  };

  const getDataTypeDetails = (dataTypesId: any) => {
    getDataType(dataTypesId)
      .then((res) => {
        setInitValues(res.data);
      })
      .catch((err) => console.log("Error while fetching data Type", err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDataTypeDetails(dataTypeId);
  }, []);

  const saveEditedDataType = async (dataType: editDataType) => {
    try {
      const { id, groupingId }: any = dataType;
      delete dataType.id;
      delete dataType.groupingId;
      delete dataType.MB;
      delete dataType.MD;
      delete dataType.CB;
      delete dataType.CT;
      delete dataType.status;
      setLoading(true);
      const res = await updateDataType(dataType, groupingId, id);
      setLoading(false);
      if (res.status === 200) {
        if (saveAndClose === true) {
          navigate("/");
          successAlert("Successfully! edited the data type.");
        } else {
          if (res.data.format === "Official") {
            navigate(`/datatype/preview/${res.data.id}`, {
              state: res.data,
            });
          } else {
            navigate(`/datatype/preview/${res.data.id}`, {
              state: res.data,
            });
          }
        }
      }
    } catch (err) {
      dataTypeEditFailedAlert(
        "Error while updating the Data type! Please try again."
      );
      console.log("Error while editing the Data type", err);
      setLoading(false);
    }
  };

  let { handleSubmit } = useForm({
    initialValues: initValues,
    onSubmit: async () => {
      if (initValues && initValues.format === "Official") {
        const combinedFieldsValues = [...fieldsValues, ...initValues.fields];
        const finalData: editDataType = {
          ...initValues,
          ...{ fields: combinedFieldsValues },
        };
        await saveEditedDataType(finalData);
      } else {
        if (initValues !== null) {
          await saveEditedDataType(initValues);
        }
      }
    },
  });

  const handleChange = (event: any) => {
    const { target } = event;
    const { name, value, type } = target;
    const formValuesObj: { [key: string]: any } =
      initValues !== null ? initValues : {};
    if (type === "checkbox") {
      formValuesObj[name] = event.currentTarget.checked ? true : false;
    } else {
      formValuesObj[name] = value;
    }

    if (type === "radio" && name === "format") {
      const newFields = [
        {
          name: "",
          inputType: "Text",
          required: false,
          columnInFile: "",
          description: "",
        },
      ];
      formValuesObj.fields = newFields;
    }
    let newValues = { values: { ...initialValues, ...formValuesObj } };
    const valuesObj = formValues(newValues);
    setInitValues(valuesObj);
    return valuesObj;
  };

  const saveFormData = () => {
    const submitBtn = document.getElementById("submitForm");
    if (formError.columnErr || formError.fieldErr) {
      dataTypeEditFailedAlert(
        "Error while saving data type. Duplicate Field Name or Column in File found!."
      );
    } else {
      submitBtn?.click();
    }
  };

  const changeInitValues = (updateInitValues: editDataType) => {
    setInitValues(updateInitValues);
  };
  const appendUniqueFields = (fieldsData: any) => {
    setFieldsValues(fieldsData);
  };
  const updateFormError = (errStatus: any) => {
    setFormError({ ...formError, ...errStatus });
  };

  const renderFormFields = (formField: any, keyId: number) => {
    return (
      <div key={keyId} className={`${formField.name} flex-1`}>
        {formField.type === "text" && (
          <TextField
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "dropdown" && (
          <Dropdown
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "radio" && (
          <Radio
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "checkbox" && (
          <Checkbox
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {loading && <Loader size="medium" />}
      {!loading && (
        <div className="pl-10">
          <ToastContainer />
          <DataTypeDetailsHeader importStatus="Draft" importType="Edit" />
          <div className="font-open-sans font-normal not-italic">
            <form
              className="bg-white rounded pt-[25px] pb-8 mb-4 data-type-form"
              onSubmit={handleSubmit}
            >
              {formData.map((formField: any, keyId: number) => {
                return renderFormFields(formField, keyId);
              })}
              <DataTypeFieldsComponent
                data={initValues!}
                changeInitValues={changeInitValues}
                appendUniqueFields={appendUniqueFields}
                updateFormError={updateFormError}
              />
              <button id="submitForm" type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form>
          </div>
          <DataTypeDetailsFooter
            saveFormData={saveFormData}
            setSaveAndClose={setSaveAndClose}
          />
        </div>
      )}
    </>
  );
};
