import { Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { updateGrouping } from "../../api";
import { groupingInputType, groupingType } from "../../types";
import drawerContext from "../../utils/drawerContext";
import { errorAlert, successAlert } from "../../utils/toast/Toast";
import Button from "../Button";
import Drawer from "../Drawer";
import Loader from "../Loader";
import "./EditGrouping.css";

type EditGroupingProps = {
  rowData: groupingType;
};

export const EditGrouping = ({ rowData }: EditGroupingProps) => {
  const [Grouping, setGrouping] = useState("");
  const [Publishing, setPublishing] = useState(false);
  const drawerCxt = useContext(drawerContext);

  const enableEditFlag = () =>
    !Grouping || rowData?.name === Grouping ? true : false;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGrouping(event.target.value);
  };

  const submitGrouping = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const updateGroupingType = event.currentTarget.name;
    const updateGroupingDetails: groupingInputType = {
      name: Grouping,
    };
    event.currentTarget.name === "save"
      ? updateGroupingDetails
      : (updateGroupingDetails.status = "Published");

    try {
      setPublishing(true);
      const res = await updateGrouping(updateGroupingDetails, rowData.id);
      if (res.status == 200) {
        updateGroupingType === "save"
          ? successAlert("Grouping Saved")
          : successAlert("Grouping Published");
        drawerCxt.GetGroupingData();
      }
    } catch (err) {
      updateGroupingType === "save"
        ? errorAlert("Error in Save Grouping")
        : errorAlert("Error in Publish Grouping");
      console.log("Error in Edit Grouping", err);
    } finally {
      setPublishing(false);
      drawerCxt.HideDrawer();
      setGrouping("");
    }
  };

  const handleEsc = (event: any) => {
    if (event.key === "Escape") {
      drawerCxt.HideDrawer();
      event?.target?.blur();
    } else handleChange;
  };

  useEffect(() => {
    setGrouping(rowData?.name);
    window.addEventListener("keydown", handleEsc);
    return () => {
      setGrouping("");
      window.removeEventListener("keydown", handleEsc);
    };
  }, [rowData]);

  return (
    <Drawer
      autoFocus={false}
      destroyOnClose
      extra={
        <Space>
          <Button
            data-test-id="button"
            name="save"
            disabled={enableEditFlag()}
            onClick={submitGrouping}
            className="save-button border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded text-base text-center dark:hover:border-hover-color dark:bg-white"
          >
            Save
          </Button>
          {
            <Button
              data-test-id="button"
              name="publish"
              disabled={!Grouping}
              onClick={submitGrouping}
              className="publish-button bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-center text-white text-base font-open-sans font-normal rounded hover:from-dark-blue-color hover:to-dark-blue-color"
            >
              Publish
            </Button>
          }
        </Space>
      }
    >
      <>
        <div>
          <div className="text-3xl font-open-sans font-normal ">Edit</div>
          <form id="update-form">
            <div className="flex flex-wrap -mx-3 mb-6 ">
              <div className="w-full px-3 py-5">
                <label className="block tracking-wide font-open-sans text-light-black-color text-small-custom-1 font-normal mb-1">
                  Grouping
                </label>
                <input
                  className="appearance-none border-b font-open-sans font-normal text-base border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500"
                  type="text"
                  maxLength={250}
                  value={Grouping || ""}
                  placeholder="Enter Grouping Name"
                  onChange={handleChange}
                />
              </div>
            </div>
          </form>
          {Publishing && <Loader size="medium" />}
        </div>
      </>
    </Drawer>
  );
};
export default EditGrouping;
