import { useAuth0 } from "@auth0/auth0-react";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

export const SideBar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  return (
    <div className="bg-sidebar-color relative h-full min-h-screen">
      <Menu defaultSelectedKeys={["2"]} theme="dark" mode="inline">
        <Menu.Item className="custom-menu-item" key="1">
          <div className="text-white font-normal text-base custom-menu-title">
            Home
          </div>
        </Menu.Item>
        <Menu.Item
          className="custom-menu-item"
          key="2"
          onClick={() => navigate("/")}
        >
          <div className="text-white font-normal text-base custom-menu-title">
            Manage Import Types
          </div>
        </Menu.Item>
        <Menu.Item
          className="custom-menu-item"
          key="3"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <div className="text-white font-normal text-base custom-menu-title">
            Log Out
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};
