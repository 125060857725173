import { Layout } from "antd";
import { routes as Routes } from "../../routes";
import { SideBar } from "./sidebar";
const { Sider, Content } = Layout;

export const Shell = () => {
  return (
    <div className="font-sans antialiased">
      <Layout>
        <Sider>
          <SideBar />
        </Sider>
        <Layout className="bg-white">
          <Content>
            <Routes />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
