import { Select } from "antd";
import { useState } from "react";
import getValue from "./getValue";

const { Option } = Select;

type DropdownProps = {
  type: string;
  keyId: number;
  label: string;
  name: string;
  required: boolean;
  values: any;
  handleChange: (event: any) => void;
  options: { label: string; value: string }[];
  onDropDownChange: () => void;
  disabled: boolean;
  multiple: boolean;
  selHeader: any;
  isInputType?: boolean;
  inputTargetId?: number;
  errorFunc?: any;
};

const Dropdown = ({
  type,
  keyId,
  label,
  name,
  values,
  handleChange,
  options,
  disabled,
  isInputType,
  inputTargetId,
  errorFunc,
}: DropdownProps) => {
  const defaultValue = getValue(name, values);
  let baseClassString = `select-dropdown rounded border-b font-open-sans font-normal text-base border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500 h-10 ${
    !isInputType ? "w-6/12" : ""
  }`;

  const cleanClassString = " border-gray-600 focus:border-gray-500";
  const errorClassString = " border-red-500 focus:border-red-500";
  const [classString, setClassString] = useState<string>(
    baseClassString + cleanClassString
  );

  const onChange = (target: any) => {
    if (errorFunc) {
      const hasError = errorFunc(target.target.value);
      if (hasError) {
        setClassString(baseClassString + errorClassString);
      } else {
        setClassString(baseClassString + cleanClassString);
      }
    }
    handleChange(target);
  };
  return (
    <div className="select-wrapper">
      <div className="mb-4">
        {label ? (
          <label className="text-small-custom-3 text-light-black-color">
            {label}
          </label>
        ) : null}

        <div>
          <Select
            key={keyId}
            onChange={(value: string) => {
              const target = {
                name,
                value,
                type,
                inputTargetId,
              };
              onChange({ target });
            }}
            value={defaultValue}
            disabled={disabled}
            className={classString}
          >
            {options &&
              options.map((option, index) => (
                <Option value={option.value} key={index + 1}>
                  {option.label}
                </Option>
              ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
