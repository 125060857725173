import { useState } from "react";
import { dataTypeFields } from "../../types";
import getValue from "./getValue";

type TextFieldProps = {
  keyId: number | string;
  label: string | undefined;
  name: string;
  values: dataTypeFields;
  handleChange: (event: any) => void;
  value: any;
  placeholder: any;
  maxLength?: number;
  isInputType?: boolean;
  inputTargetId?: number;
  inputClasses?: string;
  errorFunc?: any;
};
const TextField = ({
  keyId,
  label,
  name,
  values,
  handleChange,
  value,
  placeholder,
  maxLength,
  isInputType,
  inputTargetId,
  inputClasses,
  errorFunc,
  ...allProps
}: TextFieldProps) => {
  let baseClassString = `appearance-none border-b font-open-sans font-normal text-base block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md h-10 border-b-1 ${
    !isInputType ? "w-6/12" : ""
  }`;

  baseClassString += inputClasses ? " " + inputClasses : "";

  const cleanClassString = " border-gray-600 focus:border-gray-500";
  const errorClassString = " border-red-500 focus:border-red-500";
  const [classString, setClassString] = useState<string>(
    baseClassString + cleanClassString
  );

  const onChange = (event: any) => {
    if (errorFunc) {
      const hasError = errorFunc(event.target.value as string);
      if (hasError) {
        setClassString(baseClassString + errorClassString);
      } else {
        setClassString(baseClassString + cleanClassString);
      }
    }
    handleChange(event);
  };

  return (
    <div key={keyId} className="mb-4">
      {label ? (
        <label className="text-small-custom-3 text-light-black-color">
          {label}
        </label>
      ) : null}

      <input
        {...allProps}
        name={name}
        value={getValue(name, values)}
        onChange={onChange}
        placeholder={placeholder}
        key={keyId}
        autoComplete="off"
        className={classString}
        maxLength={maxLength || 180}
        step={isInputType && inputTargetId ? inputTargetId : 0}
      ></input>
    </div>
  );
};

export default TextField;
