import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { ClientContextProvider } from "react-fetching-library";
import { Client } from "./api/Client";
import { Shell } from "./components";
import { tokenUtil } from "./utils/apiClient";
//import { getHeapAnalytics } from "./utils/heapAnalytics";

const App = () => {
  const {
    isLoading,
    error,
    user,
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [isShellReady, setShellReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && !user) {
        await loginWithRedirect({
          redirectUri: window.location.origin,
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
    };

    fetchData().catch(console.error);
  }, [isLoading, user, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      tokenUtil.setAccessTokenSilently(getAccessTokenSilently);
      setShellReady(true);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  /*  useEffect(() => {
    getHeapAnalytics(process.env.REACT_APP_HEAP_APP_ID || "");
  }, []); */

  if (error) {
    return <div>Oops... {JSON.stringify(error)}</div>;
  }

  if (isLoading || !isShellReady) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <ClientContextProvider client={Client}>
      <Shell />
    </ClientContextProvider>
  );
};
export default App;
