import axios, { AxiosRequestConfig } from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export const audience = process.env.REACT_APP_AUTH0_API_AUDIENCE;

const getApiUrl = async () => {
  const { data: config } = await axios.get("/config.json");
  return `${config.apiUrl}`;
};

export const apiClient = async (configs: AxiosRequestConfig): Promise<any> => {
  const token = await tokenUtil.getAccessTokenSilently()({ audience });
  return axios({
    ...configs,
    headers: {
      ...configs.headers,
      Authorization: `Bearer ${token}`,
    },
    baseURL: API_URL ? API_URL : await getApiUrl(),
  });
};

let getAccessTokenSilently: Function = () => {};

export const tokenUtil = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: Function) => (getAccessTokenSilently = func),
};
