const statesData = [
  {
    label: "All Clients",
    value: "all clients",
  },
  {
    label: "Alabama",
    value: "alabama",
  },
  {
    label: "Alaska",
    value: "alaska",
  },
  {
    label: "Arizona",
    value: "arizona",
  },
  {
    label: "Arkansas",
    value: "arkanas",
  },
  {
    label: "California",
    value: "california",
  },
  {
    label: "Colorado",
    value: "colorado",
  },
  {
    label: "Connecticut",
    value: "connecticut",
  },
  {
    label: "Delaware",
    value: "delaware",
  },
  {
    label: "Florida",
    value: "florida",
  },
  {
    label: "Georgia",
    value: "georgia",
  },
  {
    label: "Hawaii",
    value: "hawaii",
  },
  {
    label: "Idaho",
    value: "idaho",
  },
  {
    label: "Illinois",
    value: "illinois",
  },
  {
    label: "Indiana",
    value: "indiana",
  },
  {
    label: "Iowa",
    value: "iowa",
  },
  {
    label: "Kansas",
    value: "kansas",
  },
  {
    label: "Kentucky",
    value: "kentucky",
  },
  {
    label: "Louisiana",
    value: "louisiana",
  },
  {
    label: "Maine",
    value: "maine",
  },
  {
    label: "Maryland",
    value: "maryland",
  },
  {
    label: "Massachusetts",
    value: "massachusetts",
  },
  {
    label: "Michigan",
    value: "michigan",
  },
  {
    label: "Minnesota",
    value: "minnesota",
  },
  {
    label: "Mississippi",
    value: "mississippi",
  },
  {
    label: "Missouri",
    value: "missouri",
  },
  {
    label: "Montana",
    value: "montana",
  },
  {
    label: "Nebraska",
    value: "nebraska",
  },
  {
    label: "Nevada",
    value: "nevada",
  },
  {
    label: "New Hampshire",
    value: "newhampshire",
  },
  {
    label: "New Jersey",
    value: "newjersey",
  },
  {
    label: "New Mexico",
    value: "newmexico",
  },
  {
    label: "New York",
    value: "newyork",
  },
  {
    label: "North Carolina",
    value: "northcarolina",
  },
  {
    label: "North Dakota",
    value: "northdakota",
  },
  {
    label: "Ohio",
    value: "ohio",
  },
  {
    label: "Oklahoma",
    value: "oklahoma",
  },
  {
    label: "Oregon",
    value: "oregon",
  },
  {
    label: "Pennsylvania",
    value: "pennsylvania",
  },
  {
    label: "Rhode Island",
    value: "rhodeisland",
  },
  {
    label: "South Carolina",
    value: "southcarolina",
  },
  {
    label: "South Dakota",
    value: "southdakota",
  },
  {
    label: "Tennessee",
    value: "tennessee",
  },
  {
    label: "Texas",
    value: "texas",
  },
  {
    label: "Utah",
    value: "utah",
  },
  {
    label: "Vermont",
    value: "vermont",
  },
  {
    label: "Virginia",
    value: "virginia",
  },
  {
    label: "Washington",
    value: "washington",
  },
  {
    label: "West Virginia",
    value: "westvirginia",
  },
  {
    label: "Wisconsin",
    value: "wisconsin",
  },
  {
    label: "Wyoming",
    value: "wyoming",
  },
];

export const validatioTypeDropDownValue: any = {
  Text: [
    {
      label: "Acceptable Values",
      value: "AcceptableValues",
    },
    {
      label: "Maximum Number of Characters Allowed",
      value: "MaxCharacters",
    },
  ],
  Number: [
    {
      label: "Range",
      value: "Range",
    },
    {
      label: "Acceptable Values",
      value: "AcceptableValues",
    },
  ],
  Date: [
    {
      label: "Date",
      value: "Date",
    },
  ],
};

export const dateFormats = [
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
  {
    label: "MMDDYYYY",
    value: "MMDDYYYY",
  },
  {
    label: "MM/DD/YY",
    value: "MM/DD/YY",
  },
  {
    label: "MMDDYY",
    value: "MMDDYY",
  },
  {
    label: "MM/YYYY",
    value: "MM/YYYY",
  },
  {
    label: "MM-YYYY",
    value: "MM-YYYY",
  },
  {
    label: "MMYY",
    value: "MMYY",
  },
  {
    label: "YYYYMMDD",
    value: "YYYYMMDD",
  },
  {
    label: "YYMMDD",
    value: "YYMMDD",
  },
  {
    label: "YYYY/MM",
    value: "YYYY/MM",
  },
  {
    label: "YYYY-MM",
    value: "YYYY-MM",
  },
  {
    label: "YYMM",
    value: "YYMM",
  },
];

export const validationFormData = [
  {
    type: "radio",
    name: "required",
    label: "Required Field",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false, checked: true },
    ],
    isHorizontal: true,
  },
];

const fieldInputData = [
  {
    label: "Text",
    value: "Text",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Date",
    value: "Date",
  },
];

export const initialValues = {
  name: "",
  format: "Official",
  availability: "all clients",
  url: "",
  keyGroup: "A",
  displayScores: false,
  fields: [
    {
      name: "",
      inputType: "Text",
      required: false,
      columnInFile: "",
    },
  ],
};

export const formData = [
  {
    type: "radio",
    name: "format",
    label: "File Format",
    options: [
      { label: "Official format", value: "Official", checked: true },
      { label: "Custom Format", value: "Custom" },
    ],
    isHorizontal: true,
  },
  {
    type: "text",
    name: "name",
    label: "Name",
    placeholder: "Name",
    required: true,
    maxLength: 180,
  },
  {
    type: "dropdown",
    name: "availability",
    label: "Availability",
    options: statesData,
  },
  {
    type: "text",
    name: "url",
    label: "URL for downloadable template (optional)",
    placeholder: "Enter URL",
    maxLength: 300,
  },
  {
    type: "checkbox",
    name: "displayScores",
    label: "",
    options: [
      { label: "Display scores in Student Folder", value: "displayScores" },
    ],
  },
];

export const inputFormFields = [
  {
    type: "text",
    name: "name",
    label: "Field Name",
    required: true,
    maxLength: 180,
  },
  {
    type: "dropdown",
    name: "inputType",
    label: "Field Input Type",
    options: fieldInputData,
    required: true,
  },

  {
    type: "text",
    label: "Column in File",
    name: "columnInFile",
    placeholder: " ",
    required: true,
    maxLength: 180,
  },
  {
    type: "textarea",
    label: "Field Description",
    name: "description",
    maxLength: 180,
  },
];

export const defaultUniqueFieldsValues: any = [
  {
    name: "First Name",
    inputType: "Text",
    required: true,
    columnInFile: "",
    validationType: "MaxCharacters",
    maxCharacter: 50,
  },
  {
    name: "Last Name",
    inputType: "Text",
    required: true,
    columnInFile: "",
    validationType: "MaxCharacters",
    maxCharacter: 50,
  },
  {
    name: "Birthdate",
    inputType: "Date",
    required: true,
    columnInFile: "",
    validationType: "Date",
    dateFormat: "MM/DD/YYYY",
  },
];
