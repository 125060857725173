import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";

type detailsFooterProps = {
  saveFormData: () => void;
  setSaveAndClose: () => void;
};

export const DataTypeDetailsFooter = ({
  saveFormData,
  setSaveAndClose,
}: detailsFooterProps) => {
  const navigate = useNavigate();
  return (
    <footer className="flex flex-row justify-between items-center bg-white text-3xl text-white text-center border-t-2 fixed inset-x-0 bottom-0 h-16 datatype-details-footer">
      <div>
        <Button
          onClick={() => navigate("/")}
          type="button"
          className="font-open-sans font-bold text-cancel-button-color text-small-custom-3 text-center datatype-details-close-button"
        >
          Cancel
        </Button>
      </div>
      <div>
        <Button
          className="border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded px-4 py-2 text-center dark:hover:border-hover-color dark:bg-white mr-[17px] save-and-close-button"
          onClick={() => {
            setSaveAndClose();
            saveFormData();
          }}
        >
          Save and Close
        </Button>
        <Button
          className="bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-white font-open-sans font-normal py-2 px-4 rounded hover:from-dark-blue-color hover:to-dark-blue-color text-center preview-button"
          onClick={() => {
            saveFormData();
          }}
        >
          Preview
        </Button>
      </div>
    </footer>
  );
};
